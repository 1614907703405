import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageChapter11 from '../common/src/assets/image/charity/specter/kapitola_11_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Jedenásta kapitola: Znesvätenie umenia | Ako duch komunizmu vládne nášmu svetu"
           description="Jedenásta kapitola: Znesvätenie umenia"
           image={imageChapter11}/>
        <h1>Jedenásta kapitola: Znesvätenie umenia</h1>
      <h2>1. Umenie: dar z nebies</h2>
      <p>V priebehu mnohých rokov ľudskej civilizácie človek uvažoval o tom, v&nbsp;čom tkvie skutočná krása. Veriaci ľudia vedia, že všetky divy sveta majú božský pôvod. Umenie s vnútornou hĺbkou sa pokúša napodobniť a zobraziť krásu neba v ľudskom svete. Inšpirácia pochádza z nebies, a ak sa umelcom dostane nebeského požehnania a múdrosti, môžu sa stať vo svojej oblasti vynikajúcimi osobnosťami.</p>
      <p>Veľkí renesanční umelci, vedení silnou vierou a zbožnosťou, smerovali svoje tvorivé úsilie k tomu, aby vytvorili diela, ktoré velebia Boha. Stredovekí renesanční umelci ako Leonardo da Vinci, Michelangelo a Raffael ovládli techniky, ktoré ďaleko prevyšovali techniky ich predchodcov a rovesníkov. Ich majstrovské diela – vrátane malieb, sôch a architektúry – sa stali nadčasovou klasikou umeleckého sveta.</p>
      <p>Po celé storočia boli tieto umelecké diela vzorom pre ľudstvo. Vďaka tomuto umeniu môžu umelci neskorších generácií nielen oceniť a študovať čistú umeleckú techniku, ale aj verejnosť môže skutočne pocítiť a uvidieť božskú prítomnosť. Ak budú tieto diela, techniky, ktoré ich vytvorili, a duch, ktorý týchto umelcov napĺňal, zachované, ľudská spoločnosť bude schopná udržať si svoje spojenie s nebesami. Potom, aj keď bude ľudstvo prechádzať obdobím dekadencie a úpadku, bude mať vďaka týmto dielam nádej, že sa navráti k tradícii a nájde cestu k spáse.</p>
      <p>Rovnaké zásady platia aj v hudobnej oblasti. Jedno príslovie, ktoré údajne pochádza z&nbsp;nemeckej opernej scény, hovorí: „Bach nám dal Božie slovo. Mozart nám dal Boží smiech. Beethoven nám dal Boží oheň. Boh nám dal hudbu, aby sme sa mohli modliť bez slov.“ Johann Sebastian Bach po celý život považoval za najvyšší princíp pri tvorení svojej hudby oddanosť Bohu, velebenie a uctievanie Boha. Na všetkých jeho dôležitých hudobných partitúrach nájdeme písmená SDG – skratku slov „Soli Deo gloria“, čo znamená „jedine Bohu patrí sláva“.</p>
      <p>Najvyššiu úroveň umelec dosiahne, ak sa mu podarí zhmotniť nebeské predmety v ľudskej ríši prostredníctvom božského zjavenia. Veľké maľby a sochy a najnádhernejšie hudobné skladby v ranom, barokovom a klasickom kánone boli všetky vytvorené veriacimi ľuďmi a predstavujú vrchol umeleckej tvorby dosiahnutej človekom.</p>
      <p>Tri najdôležitejšie prvky umeleckej tvorby sú reprezentácia, vytváranie a komunikácia. Všetky umelecké výtvory obsahujú tému, teda odkaz,&nbsp; ktorý sa umelec snaží komunikovať, bez ohľadu na formu diela – či už je to báseň, maľba, socha, fotografia, román, hra, tanec alebo film. Umelec prenáša témy do srdca čitateľa, poslucháča alebo diváka. Tento proces je komunikáciou – prenosom umelcovej mysle smerom k príjemcovi.</p>
      <p>Aby umelci dosiahli cieľ komunikácie, musia mať vynikajúcu schopnosť napodobniť a reprezentovať, pričom ​predmetom napodobňovania je svet bohov či človeka, alebo i podsvetia. Umelecká tvorba je procesom zdokonaľovania hlbších alebo podstatnejších prvkov stvárňovaného objektu. To od umelca vyžaduje posilnenie vlastnej schopnosti komunikácie a schopnosti dotknúť sa srdca ľudí. Pokiaľ má umelec spravodlivú vieru v nebesá a v morálku, nebesá mu poskytnú inšpiráciu k tvorbe. Takéto diela potom budú božské, čisté a dobrotivé – prospešné pre umelca aj pre spoločnosť.</p>
      <p>Na druhej strane, keď umelec opustí morálne štandardy, do tvorivého procesu budú vložené negatívne prvky, umelca ovplyvnia zlé sily a využijú ho k vyobrazeniu ohavných výtvorov z podsvetia. Diela tohto druhu škodia autorovi aj širšej spoločnosti.</p>
      <p>Z&nbsp;tohto je zrejmá hodnota pravoverného, ​​tradičného umenia. Božská kultúra a umenie na Východe a na Západe predstavovali spojenie medzi nebesami a ľudskou civilizáciou a mali byť ich prostredníkmi. Myšlienky a posolstvo odovzdávané týmto umením sú krása, dobrotivosť, svetlo a nádej. Na druhej strane, tí, čo sú pod kontrolou zlých prvkov, vytvárajú skazené „umelecké“ diela. Vrážajú klin medzi človeka a Boha a ťahajú človeka smerom k zlu.</p>
      <p>&nbsp;</p>
      <h2>2. Obrovský vplyv umenia na ľudstvo</h2>
      <p>Veľké umelecké diela odovzdávajú dedičstvo ľudstva ďalším generáciám, šíria vedomosti a múdrosť a posilňujú charakter. Vo veľkých civilizáciách na Západe i na Východe zaujímajú význačné postavenie.</p>
      <p>Staroveký grécky matematik a filozof Pytagoras veril, že tajomstvo hudby spočíva v jej napodobňovaní harmónie nebeských telies, ktorá odráža harmóniu vesmíru.</p>
      <p>Podobné názory zastávali aj Číňania. Pojednania o hudbe a harmónii diskutujú o tom, ako hudba korešponduje s piatimi prvkami (kovom, drevom, vodou, ohňom a zemou) a o tom, ako sú hudobné nástroje vytvorené tak, aby reprezentovali a napodobňovali vzory neba a zeme. Iba týmto spôsobom je možné dosiahnuť „hudbu toho najveľkolepejšieho štýlu“, ktorá má „rovnakú harmóniu, aká panuje medzi nebom a zemou“ <a href="#_edn1" id="_ednref1">[1]</a>. Takáto hudba je schopná nielen privábiť nebeské vtáky, ako sú žeriavy a fénixy, ale slúži tiež ako pozvánka pre božstvá, aby poctili svojou prítomnosťou túto udalosť.</p>
      <p>Konfucius raz povedal: „[Dynastia Čou] je súhrou dvoch predošlých dynastií; jej spôsoby sú uhladené a elegantné. Nasledujem Čou.“<a href="#_edn2" id="_ednref2">[2]</a> Konfucius obdivoval, ako legendárni čínski vodcovia vládli za pomoci rituálu a hudby: „Múdry cisár Sun vynašiel päťstrunový hudobný nástroj, ktorý nazval Čchin, spieval na jeho melódiu o jemnom letnom južnom vánku, a&nbsp;čuduj sa svete, jeho ríša fungovala hladko [pod vplyvom jeho hudby].“ <a href="#_edn3" id="_ednref3">[3]</a> Tieto príklady ilustrujú povzbudzujúce účinky čistej a cnostnej hudby.</p>
      <p>„Hudba princa Čchina rozbíjajúca nepriateľské vojská“, ktorú zložil cisár veľkolepej dynastie Tchang, Li Š‘-min, si získala hlboký rešpekt etnických menšín žijúcich na periférii ríše Tchang. Záznam z <em>Novej knihy Tchang</em> uvádza, že mníchovi Suan-Čangovi pri ceste na západ, kam sa vypravil hľadať budhistické písma, kráľ zo vzdialenej Indie povedal: „Váš cisár musí byť svätec, pretože zložil „Hudbu princa Čchina rozbíjajúcu nepriateľské vojská.“ <a href="#_edn4" id="_ednref4">[4]</a></p>
      <p>Na francúzskom kráľovskom dvore sa počas vlády Ľudovíta XIV. prejavovala veľká elegancia v tanci a umení. Tanec obsahuje nielen techniky pohybu, ale aj spoločenskú etiketu a normy. Ľudovít XIV. prostredníctvom umenia a kultúry svojho dvora inšpiroval Európu a&nbsp;napodobňovali ho ďalšie dvory, ako aj široké obyvateľstvo po celej Európe.</p>
      <p>Pruský panovník Fridrich Veľký bol nielen vynikajúcim kráľom, ale aj skvelým hudobníkom, skladateľom a flautistom. Nariadil výstavbu berlínskej opery, osobne na operu dohliadal a otvoril ju širšej skupine spoločenských vrstiev. Opera dodnes predstavuje dôležitú súčasť nemeckej kultúry. Týchto pár príkladov objasňuje, ako môže mať pravoverné umenie dlhodobý vplyv na spoločnosť.</p>
      <p>Autentické umenie je v súlade s prírodnými zákonmi, napodobňuje božskú múdrosť a prináša so sebou osobitnú energiu a účinky. Prospieva ľuďom tým, že napĺňa ich zmysly i ducha. Najväčší umelci pracujú nielen na fyzickej a technickej úrovni, ale pracujú tiež s témou diela, na úrovni duchovnej. Títo umelci niekedy vyjadrujú pocit zážitku vyšších síl mimo tohto fyzického sveta. Tento účinok je podobný tomu, keď človek spieva ódy Bohu – ide o slávnostný a nebeský zážitok, ktorý prekračuje ľudský jazyk. V pravom umení sa nachádza rokmi nahromadená múdrosť ľudí, ich tvorivosť a inšpirácia. Často zahŕňa hlboké významy, ktoré ďaleko presahujú to, čo je vidieť na povrchu. Niektoré diela vyžarujú zvláštny druh duchovnej energie. Všetko toto má na hlbokej, duchovnej úrovni vplyv na divákov. Ten účinok je jedinečný a nemožno ho nahradiť žiadnymi inými prostriedkami.</p>
      <p>Vynikajúci umelec môže ovplyvňovať morálku spoločnosti tým, že cez dojemné príbehy a obrazy vnáša do srdca človeka určité hodnoty. Dokonca aj ľudia, ktorí nemajú hlboké vedomosti či vzdelanie, môžu z tradičného umenia získať nové pochopenie, inšpiráciu a morálne ponaučenie. Zvážme koľko ľudí sa v tradičných západných spoločnostiach naučilo rozlišovať správne od nesprávneho, či dobro od zla, prostredníctvom ľudových rozprávok ako „O Jankovi a&nbsp;Marienke“ alebo „Snehulienka“.</p>
      <p>V Číne sa mnoho generácií učilo zo štyroch veľkých románov (Príbeh jazerného brehu, Cesta na západ, Príbeh Troch ríš a Sen o Červenom pavilóne) a z tradičného umenia rozprávania a divadelných hier. Takéto diela umožňujú ľuďom pocítiť božskú veľkoleposť a vyvolávajú v ľuďoch&nbsp; túžbu prispôsobiť sa nebeským princípom.</p>
      <p>Prostredníctvom umenia neviditeľným spôsobom pôsobia aj zdegenerované hodnoty. Lektor scenáristiky Robert McKee vo svojej knihe <em>Príbeh: Podstata, štruktúra, štýl a zásady scenáristiky</em> uvádza: „Každý účinný príbeh nám odovzdáva určitú myšlienku, ktorú nám prakticky vnúti, takže jej musíme veriť. V skutočnosti je presvedčivá sila príbehu taká veľká, že môžeme uveriť jeho významu, aj keby sme ho považovali za morálne odpudzujúci.“<a href="#_edn5" id="_ednref5">[5]</a></p>
      <p>Umenie môže mať obrovský vplyv na ľudskú morálku, myslenie a správanie, a to v pozitívnom i negatívnom zmysle.</p>
      <p>Celosvetovú pozornosť si napríklad vyslúžil tzv. Mozartov efekt. Vedecká komunita vykonala celý rad štúdií o pozitívnom vplyve hudby Wolfganga Amadea Mozarta na ľudí a zvieratá. V roku 2016 sa v podrobnejšej štúdii Mozartovho efektu ukázalo, že skladateľova hudba má pozitívny vplyv na poznávacie funkcie a správanie človeka. Avšak prekvapivé je, že hranie Mozartovej hudby pospiatky má opačný účinok. Moderná atonálna hudba rakúskeho skladateľa Arnolda Schönberga má účinok podobný tomu, ako keď sa hrá Mozart obrátene, čo ukazuje škodlivé účinky tejto hudby.<a href="#_edn6" id="_ednref6">[6]</a></p>
      <p>V porovnaní s atonálnou hudbou môže mať rokenrol ešte oveľa negatívnejšie účinky. Istý výskumník zhromaždil dáta z dvoch podobných miest a zistil, že mesto, v ktorom sa prostredníctvom rozhlasu a televízie vysielalo väčšie množstvo rockových piesní, zaznamenalo o 50 percent viac prípadov tehotenstva mimo manželstva, študentov, ktorí nedokončili štúdium, úmrtí mladých ľudí, zločinov atď.<a href="#_edn7" id="_ednref7">[7]</a> Niektorá rocková hudba dokonca povzbudzuje k samovražde. Jeden komentátor ohľadom piesne slávnej rockovej hviezdy, ktorá čelila niekoľkých žalobám od rodičov mladých poslucháčov, napísal: „Jej temný rytmus a depresívny text možno určite chápať ako povzbudenie k samovražde a je nevyvrátiteľným faktom, že mladí ľudia po jej opakovanom počúvaní skoncovali so životom.“<a href="#_edn8" id="_ednref8">[8]</a> Nie je neobvyklé, že tínedžeri spáchali samovraždu podľa toho, ako sa to písalo v rockových textoch, a mnoho rockových hudobníkov samo upadlo do depresie a drogovej závislosti alebo si vzalo život.</p>
      <p>Ďalším známym príkladom negatívneho využitia umenia je nacistický propagandistický film Triumf vôle. Aj napriek tvrdeniu režisérky Leni Riefenstahlovej, že iba vytvorila dokumentárny film, tento propagandistický film vynikal svojím umeleckým majstrovstvom. Veľkolepé scény a obrazy moci prispeli k tomu, že diváci pociťovali súzvuk s energiou a silou za nimi. Veľa jej inovatívnych techník natáčania i technické majstrovstvo následne ovplyvnili filmový priemysel na ďalšie desaťročia. Film sa však tiež stal dôležitým propagandistickým dielom pre Hitlera a nacistické Nemecko a je známe ako jedno z najúspešnejších propagandistických diel v histórii. V nekrológu venovanom Riefenstahlovej, ktorý publikoval britský denník The Independent v roku 2003, sa uvádza: „Triumf vôle zviedol mnoho múdrych mužov a žien, presvedčil ich, aby [režim] obdivovali a nie opovrhovali [ním], a nepochybne pomohol nacistom získať priateľov a spojencov po celom svete.“<a href="#_edn9" id="_ednref9">[9]</a></p>
      <p>Pochopenie veľkej sily umenia nám môže pomôcť lepšie pochopiť význam tradičného umenia a to, prečo ho zlé elementy chcú podkopať a sabotovať.</p>
      <p>&nbsp;</p>
      <h2>3. Ako komunizmus sabotuje a zneužíva umenie</h2>
      <p>Pretože umenie má taký obrovský vplyv na spoločnosť, niet divu, že komunizmus využíva umenie na dosiahnutie svojho cieľa, ktorým je sociálne inžinierstvo a deštrukcia ľudskej spoločnosti.</p>
      <h3>a) Umenie v komunistických krajinách</h3>
      <p>Komunistické strany poznajú silu umenia a všetky umelecké formy premieňajú na nástroje&nbsp; vymývania mozgov. Mnoho ľudí sa vysmievalo Čínskej komunistickej strane (ČKS) za to, že zo spevákov a hercov spravila armádnych generálov. Čudujú sa nad tým, ako sa civilisti, ktorí nikdy neprešli vojenským výcvikom a neboli vycvičení v&nbsp;manipulácii so zbraňami alebo v boji, môžu stať generálmi. Komunistická strana je presvedčená, že takíto ľudia sú pri presadzovaní a podporovaní komunistického kultu rovnako dôležití ako vycvičení vojaci – a možno ešte dôležitejší. V tomto zmysle je ich vojenská hodnosť úplne v súlade so zásadami strany. Ako povedal Mao Ce-tung: „Musíme tiež mať kultúrnu armádu, ktorá je absolútne nevyhnutná pre zjednotenie našich vlastných radov a porazenie nepriateľa.“<a href="#_edn10" id="_ednref10">[10]</a></p>
      <p>Umelecké predstavenia v komunistických krajinách sú koncipované tak, aby ľudia zabudli na utrpenie, ktorým trpia za komunistickej vlády, a aby prostredníctvom umenia rozvíjali svoju lojalitu ku komunistickej strane. Tento propagandistický efekt – nazývaný „práca s mysľou“ – nemožno dosiahnuť vojenskou silou samotnou.</p>
      <p>Môžeme sa pozrieť na slávnostný otvárací ceremoniál olympijských hier v Pekingu, ktorý stál daňových poplatníkov obrovské sumy peňazí, alebo na veľký severokórejský festival spevu a tanca Arirang a baletné skupiny bývalého Sovietskeho zväzu. Všetky slúžili potrebám strany.</p>
      <p>V septembri 2011 ministerstvo kultúry ČKS organizovalo v Centre múzických umení Johna F. Kennedyho vo Washingtone, D. C., takzvaný čínsky kultúrny festival s názvom „Čína: umenie národa“. Návštevníci mohli vidieť klasický balet na motívy propagandistického diela ČKS „Červený oddiel žien“, ktorý propaguje triednu nenávisť a násilnú revolúciu.</p>
      <p>Ak by autentické umenie, ktoré má blízko k nebesiam a podporuje tradičné hodnoty, malo existovať súčasne s umením ovládaným stranou, ktoré sa používa na vymývanie mozgov verejnosti, potom by stranícke umenie stratilo svoj monopol a nemalo by žiadny účinok. Preto všetky komunistické krajiny udržujú v otázkach umenia a vydávania kníh prísnu cenzúru.</p>
      <h3>b) Komunistické prvky v pozadí avantgardného hnutia</h3>
      <p>Po stáročia sa klasické umenie odovzdávalo z generácie na generáciu. Táto tradícia pokračovala až do 20. storočia, kedy bola náhle prerušená. Proces odovzdávania bol v umení prerušený radikálnym avantgardným hnutím a dedičstvo začalo rýchlo degenerovať. Umelec Robert Florczak k tomu poznamenal: „Hlboké, inšpiratívne a krásne bolo nahradené novým, odlišným a škaredým. ... Štandardy klesali, až kým nezostali žiadne štandardy. Zostalo iba osobné vyjadrenie.“<a href="#_edn11" id="_ednref11">[11]</a> Ľudstvo tak stratilo svoj univerzálny zmysel pre estetiku.</p>
      <p>Zdroj tohto úpadku v nových umeleckých hnutiach je úzko spätý s ideologickými trendami, ktoré boli ovplyvnené komunizmom. Mnohí z týchto umelcov boli buď oddanými komunistami alebo rôznymi para-komunistami toho či onoho druhu, alebo boli pod vplyvom týchto ideológií.</p>
      <p>György Lukács, maďarský kultúrny zmocnenec Komunistickej internacionály a zakladateľ západného marxizmu, založil Frankfurtskú školu. Jednou z jeho úloh bolo vytvoriť „novú kultúrnu formu“ opustením tradičnej kultúry. Táto nová kultúrna forma vylučovala umenie, ktoré sa snažilo znázorňovať Bohov. Ako napísal Herbert Marcuse, nemecký socialista a zástupca Frankfurtskej školy: „Umenie sa vzpiera týmto [existujúcim spoločenským] vzťahom a zároveň ich prekonáva. Týmto spôsobom umenie podkopáva prevládajúce vedomie, bežnú skúsenosť.“<a href="#_edn12" id="_ednref12">[12]</a> To znamená, že marxisti zapájajú umenie do vzbury proti božstvu a&nbsp;do rozvracania morálky. Takéto pohľady dominujú v dnešnom modernom umení.</p>
      <p>Gustave Courbet, zakladateľ francúzskej realistickej školy, bol jedným z účastníkov Parížskej komúny. Bol zvolený za člena výboru Komúny a predsedu radikálnej Federácie umelcov. Courbet sa venoval transformácii starého systému a zakladaniu nových umeleckých smerov. Nariadil Federácii umelcov, aby zničila prepracovaný neoklasicistický Vendômský stĺp (ktorý bol neskôr znovu postavený). Courbet popieral, že ľudské bytosti vytvoril Boh, a bol odhodlaný používať umenie na vyjadrenie svetonázoru proletariátu a materializmu. Je známy svojím výrokom: „Nikdy som nevidel anjelov ani bohyne, takže nemám záujem ich maľovať.“<a href="#_edn13" id="_ednref13">[13]</a></p>
      <p>Courbet veril, že reforma umenia je skutočnou revolúciou. V mene maľovania toho, čo nazýval skutočnosťou, nahradil krásu škaredosťou. Vo svojich obrazoch zachytávajúcich nahotu sa zameriaval napríklad na zobrazovanie ženských genitálií, čo mal byť revolučný čin, druh vzbury, prehrešku proti tradícii, ktorým chcel podnietiť komunistický aktivizmus. Myslenie a život Courbeta ilustrujú úzke prepojenie medzi komunistickou ideológiou revolúcie a moderným umením.</p>
      <p>Pod vplyvom modernistického myslenia priniesol revolučný zápal umelcov tvoriacich koncom 19. storočia do umeleckého sveta celý rad hnutí. Na rozdiel od tradičných škôl umeleckého stvárnenia sa jednalo o avantgardné hnutia, ktoré sa výslovne snažili podkopať tradíciu. Pojem „avantgarda“ prvýkrát použili socialistickí učenci na označenie umeleckých hnutí, ktoré zodpovedali ich vlastným politickým ašpiráciám.</p>
      <p>Tieto vplyvy so sebou na konci 19. storočia priniesli impresionizmus. Moderní umelci odvtedy opustili požiadavky tradičnej olejomaľby, vrátane presnosti, proporcií, štruktúry, perspektívy a prechodov medzi svetlom a tieňom. Potom sa vynoril neoimpresionizmus (pointilizmus) a postimpresionizmus, ktorých diela sa zameriavali na vyjadrenie osobných pocitov umelca. Medzi predstaviteľov tejto školy patria Georges-Pierre Seurat a Vincent van Gogh, ktorí inklinovali k socializmu. Van Gogh bol závislý na alkohole a neskôr vo svojom živote trpel duševnou chorobou. Jeho obrazy zjavne odrážali svet, ktorý ľudia zažívajú, keď sú pod vplyvom drog.</p>
      <p>Umelecké diela obsahujú posolstvá, ktoré ich tvorcovia chcú ľuďom odovzdať. Umelci vo vrcholnom období renesancie priniesli svojim divákom súcit a krásu. Oproti tomu obrazy súčasných umelcov vyžarujú negatívne a temné odkazy. Moderní umelci opúšťajú svoje vlastné myšlienky a nechávajú sa ovládať bytosťami a duchmi nízkych úrovní. Sami sú často nekonzistentní a zmätení, a ich diela sú podobné – tmavé, negatívne, hmlisté, šedé, depresívne, dekadentné a neusporiadané.</p>
      <p>Po impresionizme prišiel expresionizmus a fauvizmus, a potom nasledoval kubizmus Pabla Picassa. Sám Picasso vstúpil v roku 1944 do Francúzskej komunistickej strany. Vo svojom liste „Prečo som sa stal komunistom“ napísal: „Môj vstup do komunistickej strany je logickým krokom v mojom živote a v mojej práci, a dáva im zmysel. ... Svojím vlastným spôsobom som vždy hovoril, čo som považoval za najpravdivejšie, najspravodlivejšie a najlepšie, a teda za najkrajšie. Ale počas útlaku a povstania som cítil, že to nestačí, že musím bojovať nielen maľbou, ale celou svojou bytosťou.“<a href="#_edn14" id="_ednref14">[14]</a></p>
      <p>Picasso podporoval pretrhnutie väzieb s klasickými spôsobmi maľovania. Všetko považoval len za kúsok cesta, ktoré stačí zdvihnúť a vytvarovať tak, ako sa mu zapáči. Čím boli jeho diela strašidelnejšie, tým sa zdal šťastnejší. Proces vytvárania obludných obrazov je procesom ničenia obrazu, až do bodu, keď mu už nikto nemôže rozumieť. Dokonca aj Georges Braque, moderný umelec, ktorý založil kubizmus spolu s Picassom, bol pri prezeraní Picassových „Avignonských slečien“ zdesený ich škaredosťou a intenzitou. Vyhlásil, že Picasso „vypil terpentín a chrlí oheň“ a pôsobí skôr ako karnevalový herec než ako skutočný umelec.<a href="#_edn15" id="_ednref15">[15]</a></p>
      <p>Marcel Duchamp, zakladajúci člen dadaistického umeleckého hnutia, sa tiež snažil rozvrátiť tradíciu a vzbúriť sa proti nej tým, že vystavoval a používal priemyselne vyrábané predmety (tzv. ready-mades). Nájdené alebo továrňami vyrábané predmety premieňal na takzvané umelecké inštalácie. Duchamp, ktorý je nazývaný otcom konceptuálneho umenia, obhajoval myšlienku, že umením možno nazvať čokoľvek. Dadaistické hnutie je samo osebe komunistickým projektom, o čom svedčí manifest berlínskych dadaistov, ktorý vyzýval k „medzinárodnému revolučnému spojeniu všetkých tvorivých a intelektuálnych mužov a žien na základe radikálneho komunizmu“ ako aj k „okamžitému zabaveniu majetku (socializácii) a komunálnemu dodávaniu stravy pre všetkých“ a „budovaniu miest svetla a záhrad, ktoré budú patriť spoločnosti ako celku a budú pripravovať človeka na stav slobody“.<a href="#_edn16" id="_ednref16">[16]</a></p>
      <p>Dadaistická kritika tradície sa vo Francúzsku rozvinula do surrealizmu, ktorý reprezentuje komunista André Breton, ktorý obhajoval revolúciu. Bol proti údajnému útlaku, ktorý človeku prináša rozum, kultúra a spoločnosť – čo bol pohľad typický pre vtedajších európskych umelcov.</p>
      <p>Medzi umelecké hnutia, ktoré rozširovali tieto princípy, patrí abstrakcionizmus, minimalizmus, pop-art a postmodernizmus. Abstrakcionizmus je o emocionálnom vyjadrení rebélie, neporiadku, prázdnoty a úniku pred skutočnosťou. V týchto dnešných umeleckých „izmoch“ je viditeľné otvorené pošliapavanie morálnych hodnôt. Títo umelci sa vo svojich dielach dopúšťajú aj takých nehanebností, ako je otvorené znesväcovanie náboženských postáv, ako bol Ježiš Kristus.</p>
      <p>Nie všetci moderní umelci podporujú ľavicovú politiku, avšak s komunistickým myslením tu existuje jasná ideologická podobnosť – a tou je odmietnutie konceptu nebies a snaha nahradiť Boha, ako východiskového bodu pre pochopenie ľudského života. Tieto „-izmy“ začali mať čoraz väčší vplyv vo verejnej sfére a nakoniec viedli k úplnej marginalizácii klasického umenia.</p>
      <h3>c) Prevrátenie tradičnej estetiky: Škaredé sa stáva umením</h3>
      <p>Početné školy moderného umenia, ktoré sa objavili a rozvíjali, zdieľajú niekoľko spoločných znakov: prevracajú konvenčnú estetiku, pokladajú škaredosť za krásu a chcú šokovať svojou ohavnosťou do takej miery, ako to len umelcova predstavivosť dovolí.</p>
      <p>Marcel Duchamp sa podpísal na pisoár a pomenoval ho „Fontána“, aby ho mohol vystaviť pre verejnosť v New Yorku. Hoci tento objekt nebol nikdy vystavený, toto Duchampovo gesto bolo medzi jeho rovesníkmi v umeleckom svete považované za šikovný vtip a umelci a akademici to neskôr pokladali za vrchol tvorivosti. Také je prostredie v umeleckom svete, kde je klasická maľba na stojanoch marginalizovaná a populárnymi sa stali umelecké inštalácie. V roku 1958 usporiadal Yves Klein v galérii Iris Clert v Paríži výstavu s názvom Prázdnota – ukázalo sa, že zobrazovanými dielami sú prázdne, biele steny.</p>
      <p>Hlavnou postavou povojnovej nemeckej avantgardy bol Joseph Beuys, ktorý si v roku 1965 pomazal hlavu medom a zlatými lístkami a tri hodiny bez prestávky čosi mrmlal k mŕtvemu zajacovi, ktorého mal na ruke, v predstavení „Ako vysvetliť obrazy mŕtvemu zajacovi“. Podľa Beuysa mohol byť umelcom ktokoľvek. Anekdota hovorí, že pri jednej príležitosti na neho jeden frustrovaný divák zakričal: „Hovoríte o všetkom pod slnkom, len nie o umení!“ Na to mu Beuys odpovedal: „Všetko pod slnkom je umenie!“<a href="#_edn17" id="_ednref17">[17]</a></p>
      <p>V roku 1961 Piero Manzoni, kľúčová postava avantgardy, vyhlásil, že naplnil 90 plechoviek vlastnými výkalmi. Potom ich označil za umelecké diela a poskytol ich na predaj pod názvom „Merda d'artista“ („Umelcovo hovno“). V roku 2015 sa jedna z plechoviek predala v Londýne za rekordnú cenu 182 500 libier, teda vyše 205 000 eur, čo je stonásobok ceny zlata o rovnakej hmotnosti. Tiež sa podpísal na telá nahých žien ako súčasť umeleckej série, ktorú nazval „Sculture Viventi“ („Živé sochy“).</p>
      <p>V Číne si jeden nahý „umelec“ potrel telo medom a rybím tukom, aby prilákal muchy. Znesvätenie tela malo zrejme slúžiť na znázornenie myšlienky, že život je lacný, škaredý a nechutný. V dokumente BBC „Beijing Swings“ o čínskych „extrémnych umelcoch“ sa hovorí o tzv. performance art, ktoré zahŕňa vystúpenie s jedením ľudského plodu. Potom, čo sa dielo dočkalo verejného odsúdenia s tým, že takéto umenie je „ohyzdné“, moderátor dokumentu, umelecký kritik Waldemar Januszczak, mimovoľne odhalil pravú povahu dokumentu svojim komentárom: „Stojí za to pokúsiť sa pochopiť, prečo Čína produkuje najohavnejšie a najtemnejšie umenie na celom svete.“<a href="#_edn18" id="_ednref18">[18]</a> V skutočnosti to je výsledok usilovania sa o démonické veci. Niektoré z týchto moderných, takzvaných umeleckých diel, sú také ohavné a nehanebné, že prekračujú duševnú odolnosť normálnych ľudí. Takéto správanie avantgardy je „kultúrnou revolúciou“ umeleckého sveta.</p>
      <p>Tí, ktorí podporujú modernizmus, sa cítia ako ryba vo vode, avšak maliarom, ktorí skutočne ovládajú techniky ​​maľovania, nastali krušné časy. Maliari a sochári, ktorí sa striktne držia tradície a stali sa majstrami svojho remesla namáhavým výcvikom, boli vytlačení z umeleckého sveta. John William Godward, anglický neoklasicistický maliar viktoriánskej éry spájaný s p<em>rerafaelitským bratstvom</em>, cítil, že je diskriminovaný, pretože jeho štýl realistického klasického obrazu upadol do nemilosti s nástupom Picassových modernistických diel. V roku 1922 spáchal samovraždu a v liste na rozlúčku údajne napísal: „Svet nie je dosť veľký pre mňa a Picassa zároveň.“<a href="#_edn19" id="_ednref19">[19]</a></p>
      <p>Podobné metódy boli prijaté na podkopanie hudby. Autentická hudba dodržiava hudobnú teóriu a poriadok. Hudobné ladenie s tónmi a štýlmi, ktoré vytvára, vychádza z harmonických prírodných vzorcov. Vesmír, ktorý Boh stvoril, je harmonický. Ľudia sú schopní oceniť a podieľať sa na harmónii vesmíru, a tak môžu vytvoriť krásne diela, pretože boli taktiež stvorení Bohom.</p>
      <p>Moderná atonálna hudba odmieta myšlienky ako je tonalita, akordy a melódie, a v štruktúre jej chýba akýkoľvek poriadok. Takáto hudba je vzburou proti klasickej hudbe, ktorú ľuďom odovzdali Bohovia. Atonálna hudba porušuje harmóniu vo vesmíre, čo je dôvod, prečo ju mnoho poslucháčov považuje za nepríjemnú. Modernistickí (avantgardní) hudobníci na základe svojich pokrútených teórií estetiky argumentujú, že poslucháči si musia vytrénovať uši, aby si na takú hudbu zvykli, a potom si ju údajne môžu správne vychutnať.</p>
      <p>Schoenberg, jeden zo zakladateľov modernej hudby, predstavil svoj „dvanásťtónový systém“, v podstate atonálnu štruktúru, ktorá znamenala vytvorenie anti-klasickej hudobnej techniky. Schoenbergova hudba bola považovaná za negáciu všetkej nemeckej hudobnej kultúry, ktorá dovtedy vznikla, za zradu vkusu, cítenia, tradície a všetkých estetických princípov. Jeho hudbu Nemci v tom čase nazývali „kokaínom“: „Hrať Schoenberga znamená to isté, ako otvoriť kokaínový bar pre ľudí. Kokaín je jed. Schoenbergova hudba je kokaín.“<a href="#_edn20" id="_ednref20">[20]</a> Neskôr ho už hudobná kritika posudzovala takto: „Ide o dôkaz nesmierneho úspechu človeka, ktorý aj päťdesiat rokov po svojej smrti dokáže stále vyprázdniť akúkoľvek sálu na zemi.“<a href="#_edn21" id="_ednref21">[21]</a></p>
      <p>K širokému prijatiu Schoenberga došlo až vďaka hudobnej teórii Theodora W. Adorna, dôležitej osobnosti Frankfurtskej školy. V diele z roku 1949 „Filozofia modernej hudby“ sa Adorno pokúšal vyobraziť Schoenberga ako „kvintesenciálneho zástancu moderny v&nbsp;hudbe“ a vysvetľoval Schoenbergovu dvanásťtónovú kompozičnú metódu ako vyvrcholenie pokusu udeliť hudbe „jedinečný status a štrukturálnu sebestačnosť v reakcii na všadeprítomnú nadvládu kapitalistickej ideológie“. Filozofická podpora Adorna vytvorila predpoklady pre to, aby bol neskoršími generáciami skladateľov a hudobných kritikov Schoenbergov systém všeobecne prijímaný.<a href="#_edn22" id="_ednref22">[22]</a> Odvtedy mnohí hudobníci Schoenberga napodobňovali a jeho avantgardný štýl mal zásadný vplyv na povojnový hudobný svet.</p>
      <p>Potom, čo avantgarda pomocou modernej hudby zničila tradíciu, avantgardné umenie pomocou rokenrolu nahradilo v živote ľudí úlohu klasickej hudby. Sidney Finkelstein, popredný teoretik hudby komunistickej strany USA, otvorene vyhlásil, že hranice medzi klasickou a populárnou hudbou by sa mali odstrániť. Približne v rovnakom čase sa v USA čoraz viac presadzovala silne rytmická rocková hudba, zatiaľ čo klasická a tradičná hudba bola vytláčaná a marginalizovaná.</p>
      <p>K vlastnostiam rokenrolu patria disharmonické zvuky, neštruktúrované melódie, silné rytmické údery a emocionálny konflikt – vo svojej podstate podobný komunistickej myšlienke boja. V <em>Zápiskoch historika</em>, starovekom diele najznámejšieho čínskeho dejepisca S‘-ma Čchiena, sa píše, že len keď zvuk korešponduje s morálkou, môže byť nazvaný hudbou. Život a skladby rokenrolových hudobníkov sa zvyčajne točia okolo sexu, násilia a drog.</p>
      <p>Rokenrol spoločne s ďalšími modernými zvukmi, ako je rap či hip-hop, si získavali v Spojených štátoch čoraz väčšiu obľubu. Rapperi predvádzajú svoju vzburu proti tradícii a spoločnosti vo svojom ľahkovážnom užívaní drog, obscénnom jazyku a nevhodnom násilnom správaní. Vzhľadom k tomu, že morálka spoločnosti ako celku klesá, takéto „umelecké formy“, predtým považované za produkt subkultúry, sa dostali do širšej spoločnosti a sú dokonca vyhľadávané populárnymi hudobnými sálami.</p>
      <p>Doteraz sme sa sústredili na súčasnú situáciu vo svete výtvarného umenia a hudby. V skutočnosti bol výrazne ovplyvnený celý umelecký svet a vplyv moderného umeleckého hnutia možno vidieť v odklone od tradičných myšlienok, metód a zručností v oblastiach ako je sochárstvo, architektúra, tanec, dekorácia, dizajn, fotografia, filmy a ďalšie.</p>
      <p>Mnoho ľudí zaoberajúcich sa moderným umením silne ovplyvnila komunistická ideológia. Napríklad zakladateľka moderného tanca Isadora Duncanová bola otvorene bisexuálka a ateistka. Protestovala proti baletu a označila ho za škaredý a neprirodzený. Spolu so 150 deťmi, ktoré neprešli prakticky žiadnou tanečnou prípravou, vystúpila so svojím predstavením „Internacionála“ v roku 1921 v Moskve pre elity komunistickej strany vrátane Vladimira Lenina.<a href="#_edn23" id="_ednref23">[23]</a></p>
      <p>Pokiaľ ide o to, prečo tieto odchýlky existujú a stávajú sa trendovými, či dokonca hlavným prúdom, je to úzko späté s tým, ako komunizmus poškodil nebesami inšpirované tradičné umenie. Na povrchu to samozrejme nie je viditeľné. Situácia sa javí ako určitá forma sebaklamu, ktorý bol široko prijatý – v podobe predstavy, že ak je za tým nejaká teória, potom je to umenie.</p>
      <p>Ak sa ľudia podrobne pozrú na rozdiely medzi avantgardným umením a tradičným umením, zistia, že umelci z obdobia renesancie nielenže využívali umenie na chválu Boha, ale aj na prezentovanie krásy povznášajúcim spôsobom, ktorý v srdci človeka vyvolával pocity pravdy a dobroty. Takto ich umenie pomáhalo udržiavať morálku spoločnosti.</p>
      <p>Rôzne mutované formy avantgardy sa na druhej strane snažia zvrátiť všetky úspechy renesancie, „zničiť povznášajúci – teda buržoázny – potenciál umenia, literatúry a hudby, aby človek, ktorý nemá spojenie s Bohom, videl svoju jedinú kreatívnu možnosť v politickej vzbure“. Škaredosť, ktorá je „tak starostlivo živená Frankfurtskou školou, narušila naše najvyššie kultúrne snaženie“ a populárna kultúra sa zase stáva „otvorene beštiálnou“, napísal jeden akademik.<a href="#_edn24" id="_ednref24">[24]</a> Obdivovanie a zbožňovanie takej škaredosti vyvoláva v človeku jeho temnú stránku: prevahu získavajú dekadentné, skazené, násilné, zlé a ďalšie druhy negatívnych myšlienok. Snaha o takúto škaredosť viedla k dekonštrukcii a zohyzdeniu nebeských scén, božskej povahy človeka, morálky a spoločnosti a dokonca k priamemu rúhaniu sa Bohu. Toto nielenže odcudzilo ľudí od Boha, ale aj od ich vrodenej božskej povahy, spoločnosti a tradičných hodnôt.</p>
      <h3>d) Pokrivenie literatúry</h3>
      <p>Literatúra je výnimočná umelecká forma. Používa jazyk k tomu, aby odovzdala múdrosť, ktorú nebesá zverili ľudstvu, a tiež zaznamenáva dôležité skúsenosti ľudstva. Dva veľké eposy starovekého Grécka, <em>Ílias </em>a<em> Odysea</em>, stvárňujú zložitú sériu udalostí okolo Trójskej vojny, a živo zobrazujú historický epos o Bohoch a ľuďoch. Cnosti odvahy, veľkorysosti, múdrosti, spravodlivosti a umiernenosti, ktoré boli v eposoch oslavované, sa stali dôležitým zdrojom hodnôt pre grécky svet a celú západnú civilizáciu.</p>
      <p>Vzhľadom na veľký vplyv literatúry ju zlé elementy používajú na kontrolu ľudí, pričom vymýšľajú a propagujú písomné diela, ktoré rozširujú ideológiu komunizmu, urážajú tradičnú kultúru, ničia ľudskú morálku a šíria pesimizmus a pasívny a nihilistický prístup k životu. Literatúra sa stala jedným z kľúčových nástrojov, ktoré komunizmus používa na ovládanie sveta.</p>
      <p>Počas dejín Sovietskeho zväzu a ČKS – za účelom vymývania mozgov širokej verejnosti – príslušné komunistické strany dali svojim intelektuálom pokyn, aby zobrazili pomocou tradičných techník život proletariátu a koncepciu triedneho vedomia, čo malo vysvetliť ideológiu a politiku komunistickej strany. Toto dalo zrod veľkému množstvu propagandistických literárnych prác, ako boli sovietske romány <em>Železný prúd</em> a&nbsp; <em>Ako sa kalila oceľ</em>, a čínske komunistické diela <em>Pieseň mladosti</em>, <em>Slnko nad riekou Sang-kan</em>, i ďalšie, ktoré mali ohromný dopad. Komunistické strany nazývajú tento štýl socialistickým realizmom“. Mao zovšeobecnil jeho funkciu ako „službu robotníkom, roľníkom a&nbsp;vojakom“ a „službu proletariátu“.<a href="#_edn25" id="_ednref25">[25]</a> Schopnosť tohto druhu literatúry vštepovať ideológiu je zrejmá a známa. Metódy, ktorými komunizmus používa literatúru na zničenie ľudstva, sa však neobmedzujú iba na tento typ.</p>
      <p>Nasledujúci text sumarizuje niektoré z hlavných cieľov a účinkov literatúry ovplyvnenej komunizmom.</p>
      <p>&nbsp;</p>
      <h4>Použitie literatúry na zničenie tradície</h4>
      <p>Veľkým krokom k zničeniu ľudstva bolo hanobenie tradičnej civilizácie, ktorá bola ľudstvu zverená nebesami. Či už v Číne alebo na Západe, komunistické prvky využívajú intelektuálov s modernými myšlienkami na vytváranie a propagáciu diel, ktoré prekrúcajú alebo znevažujú tradičnú kultúru.</p>
      <p>Počas čínskeho Hnutia za novú kultúru sa autor Lu Sün stal známy tým, že zúrivo útočil na tradíciu a odsudzoval starovekú Čínu. V jeho prvom románe <em>Bláznov denník</em> hlavný hrdina prehlasuje, že celú čínsku históriu možno zhrnúť do slov „jedenie ľudí“. Lu Sün bol Maom ocenený ako „najväčší a najodvážnejší nositeľ štandardov tejto novej kultúrnej sily“ a „vrchný veliteľ kultúrnej revolúcie v Číne“. Mao tiež povedal: „Cesta, po ktorej sa vydal, bola cestou novej čínskej národnej kultúry.“<a href="#_edn26" id="_ednref26">[26]</a></p>
      <p>Taliansky básnik Filippo Tommaso Marinetti vydal v roku 1909 „Manifest futurizmu“, ktorý presadzuje úplné odmietnutie tradície a oslavuje stroje, technológiu, rýchlosť, násilie a súťaženie. Ruský básnik a komunista Vladimir Majakovskij publikoval v roku 1913 manifest s názvom „Zaucho verejnému vkusu“, v ktorom vyjadril svoje odhodlanie odtrhnúť sa od tradičnej ruskej literatúry.</p>
      <p>&nbsp;</p>
      <h4>Obhajovanie odporných obrazov ako „reality“</h4>
      <p>Dnešní intelektuáli a umelci používajú literatúru a umenie k vykresleniu vecí alebo scén, ktoré sú škaredé, divné a desivé, s výhovorkou, že iba zobrazujú veci také, aké sú.</p>
      <p>Tradičné umenie vyjadruje harmóniu, pôvab, jasnosť, zdržanlivosť, slušnosť, rovnováhu, univerzálnosť a ideály, čo vyžaduje výber a voľbu. Podľa názoru moderných umelcov nemožno takéto diela považovať za skutočné. Tieto názory však v skutočnosti pochádzajú z nepochopenia pôvodu a funkcie umenia. Umenie vychádza z každodenného života, ale malo by bežný život presahovať, aby zároveň prinášalo potešenie i poučenie. Z tohto dôvodu si umelci počas tvorivého procesu musia vyberať, kultivovať a spracovávať to, čo chcú vykresliť.</p>
      <p>Slepé zameranie sa na toto porozumenie realizmu umelo obmedzuje hranice života a umenia. Ak je tento typ realizmu umením, potom to, čo všetci vidia a počujú, je tiež umením – prečo potom venovať čas a peniaze na výcvik umelcov?</p>
      <h4>Ničenie morálnych hodnôt</h4>
      <p>Výhovorky, ako je „vyjadrovanie skutočného ja“ a nechať voľnú ruku „prúdu vedomia“, vedú ľudí k tomu, aby opustili tradičné morálne štandardy a oddali sa svojej démonickej stránke.</p>
      <p>Francúzsky komunista a básnik André Breton definoval surrealizmus ako „psychický automatizmus, ktorým má byť vyjadrené, či už ústne, písmom alebo akýmkoľvek iným spôsobom, reálne fungovanie myslenia. Diktát myslenia, za neprítomnosti akejkoľvek kontroly vykonávanej rozumom, bez zreteľa na estetickú či morálnu stránku.“<a href="#_edn27" id="_ednref27">[27]</a></p>
      <p>Štýl písania, ktorému sa hovorí „prúd vedomia“, a surrealistické „automatické písanie“ spolu úzko súvisia. Niektorí spisovatelia na Západe začali od začiatku 20. storočia pod vplyvom psychopatológie Sigmunda Freuda experimentovať s písaním využívajúcim prúd vedomia. Tieto diela majú zvyčajne jednoduchý dej a sústredia sa na vnútorné a súkromné ​​myšlienkové procesy nevýznamných postáv (anti-hrdinov) cez príbehy zložené z voľne plynúcich náhodných myšlienok.</p>
      <p>Ľudské bytosti v sebe majú súčasne potenciál pre láskavosť ako aj pre zlo. Život by mal byť venovaný neustálemu zvyšovaniu morálnych štandardov a kultivácii cnosti prostredníctvom sebaovládania. V modernej spoločnosti má mnoho ľudí zlé, škodlivé myšlienky a túžby. Ich prejavenie na verejnosti sa rovná znečisťovaniu spoločnosti.</p>
      <h4>Uvoľnenie temnej stránky človeka v mene „kritiky“ a „protestu“</h4>
      <p>Spisovatelia a umelci v západnom slobodnom svete pod vplyvom anti-tradicionalistického sentimentu považujú všetky zákony, predpisy a morálne kódexy za obmedzujúce a potláčajúce. Sú si vedomí problémov v modernej spoločnosti a slabostí ľudskej povahy, ale namiesto toho, aby ich riešili racionálne, presadzujú extrémny individualizmus prostredníctvom kritiky a protestov, oddávajúc sa svojim osobným túžbam.</p>
      <p>Používajú degenerované prostriedky na vyjadrenie takzvaného odporu, zatiaľ čo posilňujú temnú stránku svojej povahy, oddávajú sa nenávisti, lenivosti, túžbam, žiadostivosti, agresii a honbe za slávou. Nedostatok morálneho sebaovládania nevyrieši žiadne sociálne problémy, môže ich len zhoršiť.</p>
      <p>Počas hnutia kontrakultúry v 60. rokoch 20. storočia sa americký básnik Allen Ginsberg stal predstaviteľom generácie bítnikov a dodnes je idolom tých, ktorí sa chcú vzbúriť proti spoločnosti. Jeho báseň „Kvílenie“ zobrazuje extrémny životný štýl a duševné stavy, ako je alkoholizmus, sexuálna promiskuita, užívanie drog, sodomia, sebamrzačenie, prostitúcia, rebelizmus, násilné útoky, krádeže, vagabundizmus a šialenstvo.</p>
      <p>Vzhľadom k tomu, že hnutie kontrakultúry sa inštitucionalizovalo, „Kvílenie“ sa začalo považovať za literárnu klasiku a bolo zaradené do mnohých literárnych zbierok. Ginsberg pripustil, že „ako dieťa“ bol komunistom, a dodal, že to neľutuje.<a href="#_edn28" id="_ednref28">[28]</a> Jeho idolom bol Fidel Castro a ďalší komunistickí diktátori. Zároveň široko propagoval homosexualitu a pedofíliu. Ginsberg je príkladom prepojenia medzi komunizmom a extrémnym individualizmom.</p>
      <h4>Šírenie pornografie</h4>
      <p>Začiatkom 20. storočia sa v literárnych dielach začal objavovať sexuálne explicitný obsah. Niektoré z týchto diel boli týmto obsahom presýtené, ale napriek tomu ich kritici oslavovali ako klasiku. Mnohí novinoví komentátori a učenci sa zriekli svojej spoločenskej zodpovednosti a chválili tieto pornografické diela ako vrcholné umelecké diela. Veľká časť tradičnej morálky je založená na správnych vzťahoch medzi pohlaviami a na sebaovládaní. Porušenie týchto obmedzení – bez ohľadu na akékoľvek ušľachtilé ospravedlnenie – podkopáva a ničí morálku.</p>
      <h4>Dehumanizácia ľudí</h4>
      <p>V posledných niekoľkých desaťročiach, ako sa kultúra stávala čoraz zmätenejšou, sa objavilo veľké množstvo žánrovej fikcie, vrátane thrillerov, hororov, filmov o nadprirodzených javoch a fantastických príbehov. Prostredníctvom takýchto diel môžu myseľ a telo človeka ovládať prvky nízkej úrovne, čo vedie k dehumanizácii ľudí.</p>
      <p>Hovorí sa, že „tri stopy hrubý ľad sa nevytvorí za jednu chladnú noc“. Aby degradácia literatúry dospela tak ďaleko, že sa z nej stane nástroj zla, takisto zaberie dlhú dobu a je potrebné zapojenie mnohých oblastí. Romantizmus rozšíril oblasti literatúry tým, že zachytával súkromný a vnútorný život ľudí, pričom verejnosti boli predkladané aj niektoré škaredé a bizarné javy, vrátane extrémnych a šialených ľudských psychických stavov. Skupina britských romantických básnikov sa kvôli nemorálnemu obsahu ich básní začala nazývať „Satanská škola“.</p>
      <p>Realizmus využíva výhovorku o prezentovaní skutočnosti na obhajobu zobrazovania degenerovanej stránky ľudskej povahy. Preto niektoré diela vyzdvihujú pokrivené myšlienky a nemorálne správanie. Jeden kritik nazval realizmus „romantizmom chodiacim po všetkých štyroch“.<a href="#_edn29" id="_ednref29">[29]</a></p>
      <p>Filozofia naturalizmu, ktorú presadzoval Jean-Jacques Rousseau, pripísala úpadok ľudskej morálky spoločenskému prostrediu a genetike v rodine, čím odstránila morálnu zodpovednosť jednotlivca. Estetizmus hlása „umenie pre umenie“ a tvrdí, že umenie má iba poskytnúť zmyslový podnet a nenesie so sebou žiaden morálny imperatív.</p>
      <p>Všetko umenie má v skutočnosti jemné, hlboké a dlhotrvajúce účinky&nbsp; na mravný kompas ľudí. Odmietať morálnu zodpovednosť umenia znamená otvoriť dvere nemorálnym veciam, ktoré sa potom môžu vkradnúť dovnútra. Hoci rôzne literárne školy vytvorili niektoré vysoko kvalitné diela, stvorili aj diela hrozné. Príčinou negatívnych prvkov je úplne zjavne pokles morálnych štandardov. To vydláždilo cestu komunistickej ideológii, ktorá chce prostredníctvom literatúry zničiť ľudstvo.</p>
      <p>Keď osoba píše, v jej diele sa odráža jej morálny štandard a duševný stav. Ako celková ľudská morálka klesá, v literárnych dielach prevláda negatívne myslenie spisovateľov. Takto vzniklo množstvo diel, ktoré namiesto toho, aby sa snažili prebudiť v ľuďoch dobrotu, sťahujú ľudí do pekla.</p>
      <h2>4. Obnovenie naozajstného umenia</h2>
      <p>Umenie má obrovskú moc. Dobré umenie môže napraviť srdce človeka, pozdvihnúť jeho morálku, uviesť do súladu jin a jang, a dokonca umožniť, aby sa ľudia spojili s nebom, zemou a božskými bytosťami.</p>
      <p>Duch komunizmu v minulom storočí využil ľudskú démonickú povahu a zlomyseľnosť, čo viedlo k vytvoreniu obrovského množstva takzvaného „umenia“. Ľudia boli vedení k vzbure a rúhaniu sa proti Bohu, k tomu, aby opovrhovali tradíciou a zvrhli morálku. Vo veľkej časti spoločnosti to viedlo k&nbsp;takým démonickým prejavom, že každého, kto žil v minulosti, by to hlboko šokovalo.</p>
      <p>V porovnaní s krásou tradičného umenia sú moderné diela nesmierne škaredé. Ľudské estetické normy boli zničené. Hlavným prúdom sa stalo avantgardné umenie, v ktorom sa točí veľké množstvo peňazí, zatiaľ čo tradičné, naozajstné umenie je znevažované.</p>
      <p>Umenie sa stalo prostriedkom, prostredníctvom ktorého sa ľudia oddávajú svojim túžbam a dávajú priechod svojej démonickej povahe. Hranice medzi krásou a škaredosťou, pôvabom a vulgárnosťou, či dobrotou a zlom boli rozostrené alebo dokonca úplne zmazané. Univerzálne hodnoty boli nahradené &nbsp;bizarnosťou, chaosom a temnotou. Ľudská spoločnosť je zaplavená démonickými prvkami a ľudské bytosti sú vedené smerom k&nbsp; dekadencii a deštrukcii.</p>
      <p>Iba ak si ľudstvo pozdvihne svoju morálku a navráti sa späť k viere a tradícii, môže sa stať svedkom ďalšej renesancie v umení. Až potom budeme všetci môcť uvidieť krásu, vznešenosť a nádheru toho, čím by umenie mohlo a malo byť.</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p><a href="#_ednref1" id="_edn1">[1]</a> „Record of Music,” in <em>Classic of Rites,</em> trans. James Legge, Chinese Text Project, accessed May 8, 2020, <a href="https://ctext.org/liji/yue-ji?filter=435370&amp;searchmode=showall#result"></a><a href="https://ctext.org/liji/yue-ji?filter=435370&amp;searchmode=showall#result">https://ctext.org/liji/yue-ji?filter=435370&amp;searchmode=showall#result</a>.</p>
      <p><a href="#_ednref2" id="_edn2">[2]</a> Confucius 孔子, Lun Yu 論語 [<em>The Analects of Confucius</em>] (New York: Ballantine Books, 1999), 3.14. [In Chinese]</p>
      <p><a href="#_ednref3" id="_edn3">[3]</a> Sima Qian, “A Treatise on Music,” in <em>Records of the Grand Historian,</em> trans. Burton Watson, vol. 24, 3rd ed. (New York: Columbia University Press, 1995).</p>
      <p><a href="#_ednref4" id="_edn4">[4]</a> Ouyang Xiu 歐陽脩 and Song Qi 宋祁, <em>Xin Tang Shu</em> 新唐書 [<em>New Book of Tang</em>], vol. 237 (1060). [In Chinese]</p>
      <p><a href="#_ednref5" id="_edn5">[5]</a> Robert McKee, <em>Story: Style, Structure, Substance, and the Principles of Screenwriting</em> (New York: ReganBooks, 1997), 129–130.</p>
      <p><a href="#_ednref6" id="_edn6">[6]</a> Yingshou Xing et al., “Mozart, Mozart Rhythm and Retrograde Mozart Effects: Evidences from Behaviours and Neurobiology Bases,” in <em>Scientific Reports,</em> vol. 6 (January 21, 2016), <a href="https://www.nature.com/articles/srep18744"></a><a href="https://www.nature.com/articles/srep18744">https://www.nature.com/articles/srep18744</a>.</p>
      <p><a href="#_ednref7" id="_edn7">[7]</a> David Noebel, <em>The Marxist Minstrels: A Handbook on Communist Subversion of Music</em> (Tulsa, OK: American Christian College Press, 1974), 58–59.</p>
      <p><a href="#_ednref8" id="_edn8">[8]</a> David Cloud, “Rock Music and Suicide,” Way of Life Literature, December 20, 2000, <a href="https://www.wayoflife.org/reports/rock_music_and_suicide.html"></a><a href="https://www.wayoflife.org/reports/rock_music_and_suicide.html">https://www.wayoflife.org/reports/rock_music_and_suicide.html</a>.</p>
      <p><a href="#_ednref9" id="_edn9">[9]</a> Val Williams, “Leni Riefenstahl: Film-maker Who Became Notorious as Hitler’s Propagandist,” <em>The Independent,</em> September 10, 2003, <a href="https://web.archive.org/web/20090830045819/http:/www.independent.co.uk/news/obituaries/leni-riefenstahl-548728.html"></a><a href="https://web.archive.org/web/20090830045819/http">https://web.archive.org/web/20090830045819/http</a>://www.independent.co.uk/news/obituaries/leni-riefenstahl-548728.html.</p>
      <p><a href="#_ednref10" id="_edn10">[10]</a> Mao Zedong, “Talks at the Yenan Forum on Literature and Art,” in <em>Selected Works of Mao Tse-Tung</em> (Beijing: Foreign Languages Press), Marxists Internet Archive, accessed on April 23, 2020, <a href="https://www.marxists.org/reference/archive/mao/selected-works/volume-3/mswv3_08.htm"></a><a href="https://www.marxists.org/reference/archive/mao/selected-works/volume-3/mswv3_08.htm">https://www.marxists.org/reference/archive/mao/selected-works/volume-3/mswv3_08.htm</a>.</p>
      <p><a href="#_ednref11" id="_edn11">[11]</a> PragerU, “Why Is Modern Art So Bad?”, YouTube, September 1, 2014, <a href="https://www.youtube.com/watch?v=lNI07egoefc"></a><a href="https://www.youtube.com/watch?v=lNI07egoefc">https://www.youtube.com/watch?v=lNI07egoefc</a>.</p>
      <p><a href="#_ednref12" id="_edn12">[12]</a> Herbert Marcuse, <em>The Aesthetic Dimension: Toward a Critique of Marxist Aesthetics</em> (Boston: Beacon Press, 1978), ix.</p>
      <p><a href="#_ednref13" id="_edn13">[13]</a> Jackson Spielvogel, <em>Western Civilization: Volume C: Since 1789</em> (United States: Cengage Learning, 2010), 698.</p>
      <p><a href="#_ednref14" id="_edn14">[14]</a> Pablo Picasso, “Why I Became a Communist” (1945), as quoted in “Picasso, the FBI, and Why He Became a Communist,” Meyer Schapiro Collection at Columbia University’s Rare Book &amp; Manuscript Library, February 24, 2010, accessed July 11, 2018, <a href="https://blogs.cul.columbia.edu/schapiro/2010/02/24/picasso-and-communism"></a><a href="https://blogs.cul.columbia.edu/schapiro/2010/02/24/picasso-and-communism">https://blogs.cul.columbia.edu/schapiro/2010/02/24/picasso-and-communism</a>.</p>
      <p><a href="#_ednref15" id="_edn15">[15]</a> Robert Hughes, <em>The Shock of the New: The Hundred-Year History of Modern Art—Its Rise, Its Dazzling Achievement, Its Fall</em> (London: Knopf, 1991), 24.</p>
      <p><a href="#_ednref16" id="_edn16">[16]</a> Richard Huelsenbeck and Raoul Hausmann, “What Is Dadaism and What Does It Want in Germany?”, in Charles Harrison and Paul Wood, <em>Art in Theory, 1900–2000: An Anthology of Changing Ideas,</em> 2nd ed. (Malden, Mass, Oxford: Blackwell Pub, 2003).</p>
      <p><a href="#_ednref17" id="_edn17">[17]</a> Joseph Beuys, as quoted in “Joseph Beuys: The Revolution Is Us,” Tate.org, February 23, 1993, <a href="https://www.tate.org.uk/whats-on/tate-liverpool/exhibition/joseph-beuys-revolution-us"></a><a href="https://www.tate.org.uk/whats-on/tate-liverpool/exhibition/joseph-beuys-revolution-us">https://www.tate.org.uk/whats-on/tate-liverpool/exhibition/joseph-beuys-revolution-us</a>.</p>
      <p><a href="#_ednref18" id="_edn18">[18]</a> Waldemar Januszczak, as quoted in Ben Cade, “Zhu Yu: China’s Baby-Eating Shock Artist Goes Hyperreal,” <em>Culture Trip,</em> October 5, 2016, <a href="https://theculturetrip.com/asia/china/articles/zhu-yu-china-s-baby-eating-shock-artist-goes-hyperreal"></a><a href="https://theculturetrip.com/asia/china/articles/zhu-yu-china-s-baby-eating-shock-artist-goes-hyperreal">https://theculturetrip.com/asia/china/articles/zhu-yu-china-s-baby-eating-shock-artist-goes-hyperreal</a>.</p>
      <p><a href="#_ednref19" id="_edn19">[19]</a> John Wiiliam Godward, as quoted in Brad Smithfield, “‘The World Is Not Big Enough for Me and a Picasso’: The Life and Artwork of John William Godward,” <em>The Vintage News,</em> January 10, 2017, <a href="https://www.thevintagenews.com/2017/01/10/world-not-big-enough-picasso-life-artwork-john-william-godward"></a><a href="https://www.thevintagenews.com/2017/01/10/world-not-big-enough-picasso-life-artwork-john-william-godward">https://www.thevintagenews.com/2017/01/10/world-not-big-enough-picasso-life-artwork-john-william-godward</a>.</p>
      <p><a href="#_ednref20" id="_edn20">[20]</a> Walter Frisch, ed., <em>Schoenberg and His World</em> (Princeton, NJ: Princeton University Press, 1999), 94.</p>
      <p><a href="#_ednref21" id="_edn21">[21]</a> Norman Lebrecht, “Why We’re Still Afraid of Schoenberg,” <em>The Lebrecht Weekly,</em> July 8, 2001, <a href="http://www.scena.org/columns/lebrecht/010708-NL-Schoenberg.html"></a><a href="http://www.scena.org/columns/lebrecht/010708-NL-Schoenberg.html">http://www.scena.org/columns/lebrecht/010708-NL-Schoenberg.html</a>.</p>
      <p><a href="#_ednref22" id="_edn22">[22]</a> Golan Gur, “Arnold Schoenberg and the Ideology of Progress in Twentieth-Century Musical Thinking,” <em>Search: Journal for New Music and Culture,</em> 5 (Summer 2009), <a href="http://www.searchnewmusic.org/gur.pdf"></a><a href="http://www.searchnewmusic.org/gur.pdf">http://www.searchnewmusic.org/gur.pdf</a>.</p>
      <p><a href="#_ednref23" id="_edn23">[23]</a> Julia Mickenberg, <em>American Girls in Red Russia: Chasing the Soviet Dream</em> (United States: University of Chicago Press, 2017), 216–217.</p>
      <p><a href="#_ednref24" id="_edn24">[24]</a> Michael Minnicino, “The New Dark Age: The Frankfurt School and ‘Political Correctness,’” in <em>Fidelio Magazine</em> 1, no.1 (Winter 1992), accessed April 24, 2020, <a href="http://archive.schillerinstitute.org/fid_91-96/921_frankfurt.html"></a><a href="http://archive.schillerinstitute.org/fid_91-96/921_frankfurt.html">http://archive.schillerinstitute.org/fid_91-96/921_frankfurt.html</a>.</p>
      <p><a href="#_ednref25" id="_edn25">[25]</a> Mao Zedong, “Talks at the Yenan Forum.”</p>
      <p><a href="#_ednref26" id="_edn26">[26]</a> Mao Zedong, “On New Democracy,” in <em>Selected Works of Mao Tse-Tung</em> (Beijing: Foreign Languages Press, 1942), Marxists Internet Archive, accessed on April 24, 2020, <a href="https://www.marxists.org/reference/archive/mao/selected-works/volume-2/mswv2_26.htm"></a><a href="https://www.marxists.org/reference/archive/mao/selected-works/volume-2/mswv2_26.htm">https://www.marxists.org/reference/archive/mao/selected-works/volume-2/mswv2_26.htm</a>.</p>
      <p><a href="#_ednref27" id="_edn27">[27]</a> André Breton, <em>Manifestoes of Surrealism,</em> trans. Richard Seaver and Helen Lane (Ann Arbor, MI: University of Michigan Press, 1969), 26.</p>
      <p><a href="#_ednref28" id="_edn28">[28]</a> Allen Ginsberg, “America,” <em>Selected Poems 1947–1995</em> (New York: HarperCollins Publishers Inc., 2001).</p>
      <p><a href="#_ednref29" id="_edn29">[29]</a> Irving Babbitt, <em>Rousseau and Romanticism</em> (Boston: Houghton Mifflin, 1919), 104.</p>
      <Link to={`/kapitola_12`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


